import logger from '~/services/logger';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLoaderData } from '@remix-run/react';
import { twMerge } from 'tailwind-merge';
import { addParametersToURL } from '~/services/links/index.ts';
import type {
  NavBarJSON,
  NavLinks,
  NavigationHandler,
} from '~/services/layout/navigations.ts';
import type { loader as rootLoader } from '~/routes/_index.tsx';
import { NavBarToggleMenu } from './nav-bar-toggle-menu.tsx';
import useOutsideClick from '~/hooks/use-outside-click.ts';
import type { NavbarAccountDropdown } from './user-account-dropdown.tsx';
import { UserAccountDropdown } from './user-account-dropdown.tsx';
import { SearchIconAsset } from '../screens/search.tsx';
import { useIsMobile } from '~/hooks/use-responsive-breakpoint.ts';
import { track } from '~/services/analytics/index.client.ts';
import { getNavItemAnalyticsData } from '~/utils/analytics.tsx';

type LoaderData = {
  navigations: NavigationHandler;
  searchScreenId: string | undefined;
  debug: string | undefined;
  debugLayoutId: string | undefined;
};

export default function NavBar() {
  const {
    navBarId,
    navbarLogo,
    navbarLinks,
    navbarSearchButton,
    navbarAccountDropdown,
    navbarStyles,
    navbarError,
  }: UseGetWebNavBarItems = useGetWebNavBarItems();

  const navbarRef = useRef(null);

  const { debug, debugLayoutId, analyticsTrackData } =
    useLoaderData<typeof rootLoader>();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const isMobile: boolean = useIsMobile();

  useEffect(() => {
    if (isMobile) return;

    setMenuOpen(false);
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useOutsideClick({
    elementRef: navbarRef,
    toggleSetter: toggleMenu,
    enabled: isMenuOpen,
  });

  const rootLink = addParametersToURL('/', {
    debug,
    'layout-id': debugLayoutId,
  });

  const { navbarLogoSrc } = navbarLogo;

  const onClickNavItem = React.useCallback(
    (nav_title: string) => {
      const { eventName, trackData } = getNavItemAnalyticsData(
        'tap_menu',
        analyticsTrackData,
        { selected_area: nav_title }
      );

      track(eventName, trackData);
    },
    [analyticsTrackData]
  );

  try {
    if (navbarError) return <></>;

    return (
      <nav
        className={twMerge(
          `navigation-${navBarId} bg-navbar-background-color font-navbar-font-family font-navbar-font-weight tracking-navbar-letter-spacing text-navbar-font-color`,
          'sticky top-0 z-[200] flex flex-wrap items-center justify-center p-4 lg:justify-evenly lg:gap-10'
        )}
        data-testid="top-navbar"
        ref={navbarRef}
      >
        {navbarLogoSrc && (
          <NavLink
            to={rootLink}
            className="absolute left-1/2 -translate-x-1/2 transform lg:static lg:left-auto lg:ml-5 lg:transform-none"
            onClick={() => onClickNavItem('Root')}
          >
            <img
              alt="Logo"
              className="h-navbar-logo-height w-navbar-logo-width"
              src={navbarLogoSrc}
              role="menuitem"
            />
          </NavLink>
        )}

        {navbarLogoSrc && (
          <div
            className={'-mr-10 h-navbar-logo-height bg-transparent'}
            aria-label="hidden"
            tabIndex={-1}
          ></div>
        )}

        {navbarLinks && (
          <div className="hidden flex-1 justify-evenly lg:flex lg:items-center lg:justify-normal lg:gap-navbar-items-gutter">
            <NavbarLinks
              navbarLinks={navbarLinks}
              onClickNavItem={onClickNavItem}
            />
          </div>
        )}

        {(navbarAccountDropdown || navbarSearchButton?.searchIconAsset) && (
          <>
            <div className="ml-auto hidden items-center lg:flex">
              <UserAccountDropdown />
              <SearchIconAsset />
            </div>

            <NavBarToggleMenu
              {...{
                navBarId,
                isMenuOpen,
                toggleMenu,
                navbarStyles,
              }}
            />
          </>
        )}

        <BottomMenu onClickNavItem={onClickNavItem} />
      </nav>
    );
  } catch (error: any) {
    logger.info(`NavBar: ${error.message}`);
    return <></>;
  }
}

function BottomMenu({
  onClickNavItem,
}: {
  onClickNavItem: (nav_title: string) => void;
}) {
  const { navBarId, navbarLinks }: UseGetWebNavBarItems =
    useGetWebNavBarItems();

  if (!navbarLinks || navbarLinks?.length === 0) return <></>;

  return (
    <nav
      data-bottom-navbar
      className={twMerge(
        `navigation-${navBarId} bg-navbar-background-color font-navbar-font-family font-navbar-font-weight tracking-navbar-letter-spacing text-navbar-font-color`,
        'z-50 flex flex-wrap items-center justify-center p-4',
        'fixed bottom-0 left-0 w-full lg:hidden'
      )}
    >
      <div className="flex flex-1 justify-evenly">
        <NavbarLinks
          navbarLinks={navbarLinks}
          onClickNavItem={onClickNavItem}
        />
      </div>
    </nav>
  );
}

function NavbarLinks({
  navbarLinks,
  onClickNavItem,
}: {
  navbarLinks: NavbarLink[] | [];
  onClickNavItem: (nav_title: string) => void;
}) {
  try {
    return (
      <>
        {navbarLinks &&
          React.Children.toArray(
            navbarLinks.map((menuItem: any) => {
              const isTitle =
                typeof menuItem?.title === 'string' && menuItem?.title !== '';

              if ((!isTitle && !menuItem?.assets?.icon) || !menuItem?.link)
                return <></>;

              const iconHeight = menuItem?.styles?.icon_height || 32;
              const iconWidth = menuItem?.styles?.icon_width || 32;

              return (
                <NavLink
                  role="menuitem"
                  to={menuItem.link}
                  onClick={() => onClickNavItem(menuItem.title)}
                >
                  {({ isActive }: { isActive: boolean }) => (
                    <div className="flex flex-col items-center">
                      {menuItem?.assets?.icon && (
                        <img
                          alt={isTitle ? menuItem?.title : ''}
                          className={`lg:hidden h-[${iconHeight}px] w-[${iconWidth}px]`}
                          src={menuItem?.assets?.icon}
                        />
                      )}
                      <span
                        className={twMerge(
                          isActive && `text-navbar-font-color-active`,
                          'hover:text-navbar-font-color-hover'
                        )}
                      >
                        {isTitle && (
                          <span className="text-navbar-font-size leading-navbar-line-height">
                            {menuItem.title}
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </NavLink>
              );
            })
          )}
      </>
    );
  } catch (error: any) {
    logger.info(`NavbarLinks: ${error.message}`);
    return <></>;
  }
}

export function useGetWebNavBarItems(): UseGetWebNavBarItems {
  const {
    navigations: { nav_bar },
    searchScreenId,
    debug,
    debugLayoutId,
  } = useLoaderData<LoaderData>();

  try {
    // @ts-ignore
    const navbar: NavBarJSON | undefined = nav_bar;

    if (navbar?.navigationType === 'responsive_nav_bar') {
      throw Error('useGetWebNavBarItems supports only old nav bar');
    }

    const navBarId: string | undefined = navbar?.id;

    if (!navbar || !navBarId) throw Error('navigations nav_bar N/A');

    const links: NavLinks = navbar?.links;

    if (!Array.isArray(links) || links?.length === 0)
      throw Error('Navbar links N/A');

    const navbarLogo: NavbarLogo = getNavbarLogo(links);
    const navbarLinks: NavbarLink[] | [] = getNavBarLinks(
      links,
      debug,
      debugLayoutId
    );
    const navbarSearchButton: NavbarSearchButton = getNavBarSearchButton(links);
    const navbarAccountDropdown: NavbarAccountDropdown | undefined =
      getNavBarAccountDropdown(links);

    if (
      !navbarLogo &&
      !navbarLinks &&
      !navbarSearchButton &&
      !navbarAccountDropdown
    )
      throw Error('Navbar items N/A');

    return {
      navBarId,
      navbarLogo,
      navbarLinks,
      navbarSearchButton,
      navbarAccountDropdown,
      navbarStyles: {
        ...navbar?.styles,
        mobile_toggle_button_size:
          navbar?.styles?.mobile_toggle_button_size || 24,
      } as NavBarJSON['styles'],
    };

    function getNavbarLogo(links: NavLinks): NavbarLogo {
      try {
        const navbarLogo = links.find(
          (item: any) => item?.type === 'web_navbar_logo'
        );

        const navbarLogoSrc: string | undefined = navbarLogo?.assets?.app_logo;

        return {
          navbarLogoSrc,
        };
      } catch (error: any) {
        logger.info(`getNavbarLogo: ${error.message}`);
        return {
          navbarLogoSrc: undefined,
        };
      }
    }

    function getNavBarLinks(
      links: NavLinks,
      debug?: string,
      debugLayoutId?: string
    ): NavbarLink[] | [] {
      try {
        const navbarItemLink = links.filter(
          (item: any) => item?.type === 'web_navbar_item_link'
        );

        return navbarItemLink.map(({ id, title, link, assets }) => {
          return {
            id,
            title,
            link: link
              ? addParametersToURL(link, { debug, 'layout-id': debugLayoutId })
              : link,
            assets,
          };
        });
      } catch (error: any) {
        logger.info(`getNavBarLinks: ${error.message}`);
        return [];
      }
    }

    function getNavBarSearchButton(links: NavLinks): NavbarSearchButton {
      try {
        const navbarSearchButton = links.find(
          (item: any) => item?.type === 'web_navbar_search_button'
        );

        return {
          searchIconAssetLabel:
            navbarSearchButton?.general?.search_icon_asset_mobile_label,
          searchIconAsset: navbarSearchButton?.assets?.search_icon_asset,
          searchScreenId,
        };
      } catch (error: any) {
        logger.info(`getNavBarSearchButton: ${error.message}`);
        return {
          searchIconAssetLabel: undefined,
          searchIconAsset: undefined,
          searchScreenId: undefined,
        };
      }
    }

    function getNavBarAccountDropdown(
      links: NavLinks
    ): NavbarAccountDropdown | undefined {
      try {
        const navbarAccountDropdown: any = links.find(
          (item: any) => item?.type === 'web_navbar_account_dropdown'
        );
        const userAccountScreenId: string | undefined =
          nav_bar?.userAccountScreenId;

        if (userAccountScreenId)
          navbarAccountDropdown['userAccountScreenId'] = userAccountScreenId;

        return navbarAccountDropdown;
      } catch (error: any) {
        logger.info(`getNavBarAccountDropdown: ${error.message}`);
        return undefined;
      }
    }
  } catch (error: any) {
    return {
      navBarId: undefined,
      navbarLogo: {
        navbarLogoSrc: undefined,
      },
      navbarLinks: [],
      navbarSearchButton: {
        searchIconAssetLabel: undefined,
        searchIconAsset: undefined,
        searchScreenId: undefined,
      },
      navbarAccountDropdown: undefined,
      navbarStyles: undefined,
      navbarError: error.message,
    };
  }
}

interface NavbarLink {
  id?: string;
  type?: string;
  title: string;
  link: string;
  assets?: {
    icon?: string;
    icon_selected?: string;
  };
}

interface NavbarLogo {
  id?: string;
  type?: string;
  navbarLogoSrc?: string;
}

export interface UseGetWebNavBarItems {
  navBarId: string | undefined;
  navbarLogo: NavbarLogo;
  navbarLinks: NavbarLink[] | [];
  navbarSearchButton: NavbarSearchButton;
  navbarAccountDropdown?: NavbarAccountDropdown;
  navbarStyles: NavBarJSON['styles'];
  navbarError?: string;
}

export interface NavbarSearchButton {
  id?: string;
  type?: string;
  searchIconAssetLabel?: string;
  searchIconAsset?: string;
  searchScreenId?: string;
}

export type NavItem =
  | NavbarLink
  | NavbarLogo
  | NavbarSearchButton
  | NavbarAccountDropdown;
