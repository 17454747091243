import { useEffect, useState } from 'react';

import {
  ResponsiveBreakpointNames,
  responsiveBreakpoints,
} from '~/services/layout/layout';
import type { UIComponent } from '~/services/layout';
import debounce from 'lodash.debounce';

const RESPONSIVE_BREAKPOINT_DEBOUNCE_DELAY: number = 1000;

export type UseResponsiveBreakpoint = ResponsiveBreakpointNames | 'unset';

export function useResponsiveBreakpoint(
  useDebounce = true
): UseResponsiveBreakpoint {
  const [responsiveBreakpoint, setResponsiveBreakpoint] =
    useState<UseResponsiveBreakpoint>('unset');

  const updateResponsiveBreakpointOnResize = () => {
    const windowWidth: number = window.innerWidth;

    if (
      windowWidth < responsiveBreakpoints.tablet &&
      responsiveBreakpoint !== 'mobile'
    ) {
      setResponsiveBreakpoint('mobile');
    } else if (
      windowWidth < responsiveBreakpoints.desktop &&
      responsiveBreakpoint !== 'tablet'
    ) {
      setResponsiveBreakpoint('tablet');
    } else if (
      windowWidth < responsiveBreakpoints['large-desktop'] &&
      responsiveBreakpoint !== 'desktop'
    ) {
      setResponsiveBreakpoint('desktop');
    } else {
      if (responsiveBreakpoint !== 'large-desktop')
        setResponsiveBreakpoint('large-desktop');
    }
  };

  const debouncedUpdateResponsiveBreakpointOnResize = debounce(
    updateResponsiveBreakpointOnResize,
    RESPONSIVE_BREAKPOINT_DEBOUNCE_DELAY
  );

  useEffect(() => {
    updateResponsiveBreakpointOnResize();

    window.addEventListener(
      'resize',
      useDebounce
        ? debouncedUpdateResponsiveBreakpointOnResize
        : updateResponsiveBreakpointOnResize
    );

    return () => {
      window.removeEventListener(
        'resize',
        useDebounce
          ? debouncedUpdateResponsiveBreakpointOnResize
          : updateResponsiveBreakpointOnResize
      );
    };
  }, []);

  return responsiveBreakpoint;
}

export function useResponsiveValue(
  {
    mobile,
    tablet,
    desktop,
    largeDesktop,
  }: {
    mobile: any;
    tablet: any;
    desktop: any;
    largeDesktop: any;
  },
  useDebounce = true
) {
  const breakpoint = useResponsiveBreakpoint(useDebounce);

  switch (breakpoint) {
    case 'unset':
    case 'mobile':
      return mobile;
    case 'tablet':
      return tablet;
    case 'desktop':
      return desktop;
    case 'large-desktop':
      return largeDesktop;
  }
}

export function useIsMobile(): boolean {
  const responsiveBreakpoint = useResponsiveBreakpoint();
  const isMobile: boolean =
    responsiveBreakpoint !== 'desktop' &&
    responsiveBreakpoint !== 'large-desktop';
  return isMobile;
}

/**
 * Get the cell style ID for the current responsive breakpoint.
 * If the cell style ID is not set for the current breakpoint
 * it will fallback to the next smaller breakpoint.
 * @param uiComponent - The UI component to get the cell style ID for
 * @returns The cell style ID for the current responsive breakpoint or an empty string
 */
export function useBreakpointCellId(uiComponent: UIComponent): string {
  const responsiveBreakpoint: UseResponsiveBreakpoint =
    useResponsiveBreakpoint();

  if (responsiveBreakpoint === 'unset') return '';

  const mobileCellId: string | undefined =
    uiComponent?.styles?.mobile_cell_plugin_configuration_id;
  const tabletCellId: string | undefined =
    uiComponent?.styles?.tablet_cell_plugin_configuration_id;
  const desktopCellId: string | undefined =
    uiComponent?.styles?.desktop_cell_plugin_configuration_id;
  const largeDesktopCellId: string | undefined =
    uiComponent?.styles?.large_desktop_cell_plugin_configuration_id;

  let cellId: string | undefined;

  const isMobile: boolean = responsiveBreakpoint === 'mobile';
  const isTablet: boolean = responsiveBreakpoint === 'tablet';
  const isDesktop: boolean = responsiveBreakpoint === 'desktop';
  const isLargeDesktop: boolean = responsiveBreakpoint === 'large-desktop';

  isMobile && (cellId = mobileCellId);
  isTablet && (cellId = tabletCellId);
  isDesktop && (cellId = desktopCellId);
  isLargeDesktop && (cellId = largeDesktopCellId);

  if (isTablet && !tabletCellId) {
    cellId = mobileCellId;
  }

  if (isDesktop && !desktopCellId) {
    cellId = tabletCellId || mobileCellId;
  }

  if (isLargeDesktop && !largeDesktopCellId) {
    cellId = desktopCellId || tabletCellId || mobileCellId;
  }

  return cellId || '';
}
