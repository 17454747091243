import { getPluginConfig } from '~/utils/get-plugin-configuration.ts';
import manifest from '../config/manifest.ts';

function NavItemCookieSettings({ item }: any): JSX.Element {
  const { general } = getPluginConfig<typeof manifest>(manifest, item);

  return (
    <button id="ot-sdk-btn" className="ot-sdk-show-settings flex w-fit">
      {general.text}
    </button>
  );
}

export default NavItemCookieSettings;
