import { useLoaderData, useLocation } from '@remix-run/react';
import type { loader } from '~/routes/_index.tsx';

export function useIsSearchScreen(searchScreenId: string | undefined): boolean {
  const location = useLocation();

  const { isSearchScreen } = useLoaderData<typeof loader>();

  const locationPathname: string = location.pathname;

  const isSearchScreenByPathname: boolean =
    (searchScreenId && locationPathname.includes(searchScreenId)) ||
    locationPathname.toUpperCase() === '/SEARCH';

  return isSearchScreen && isSearchScreenByPathname;
}
