import get from 'lodash.get';
import { getScreenRelativePath } from '../layout/layout.ts';
import { isValidUrl } from '../../utils/validations.ts';
import type { LinksToScreenData } from '../layout/index.server.ts';

export const playerPlugins = ['QuickBrickPlayerPlugin'];
export const playerRoute = `player`;

/**
 * Appends parameters to a given URL.
 * @param url - The original URL (optional).
 * @param params - An object containing key-value pairs of parameters to be added.
 * @returns A new URL with the added parameters, or an empty string if the URL is not provided.
 */
export function addParametersToURL(
  url: string | undefined | null,
  params: Record<string, string | null | undefined>
): string {
  // Check if the URL is provided
  if (!url) return '';

  // Use reduce to iterate over each parameter and update the URL
  return Object.entries(params).reduce((newUrl, [param, value]) => {
    // Check if the value is defined and not null
    if (value) return addParameter(newUrl, param, value);

    const isAddSlash = newUrl.startsWith('/') ? '' : '/';
    // If the value is null or undefined, return the current URL without modification
    return `${isAddSlash}${newUrl}`;
  }, url);

  /**
   * Adds a parameter to a given URL.
   * @param url - The original URL.
   * @param param - The parameter key.
   * @param value - The parameter value.
   * @returns The updated URL with the added parameter.
   */
  function addParameter(url: string, param: string, value: string): string {
    // Check if the URL already has parameters
    const separator = url.includes('?') ? '&' : '?';

    // Construct and return the updated URL
    return `${url}${separator}${param}=${value}`;
  }
}

// exported only for test
export function getScreenIdByType(type: string, contentTypes: any): string {
  return contentTypes[type]?.screen_id;
}

function getScreen(screenId: string, linksToScreenData: LinksToScreenData[]) {
  return linksToScreenData.find((screen: any) => screen.id === screenId);
}

export function convertStringToUrlSegment(string: string) {
  return string
    .replace(/[^a-zA-Z0-9\s]+/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
}

export function getVideoIdFromUrl(url: string) {
  if (isValidUrl(url)) {
    return url.replace(/[^0-9]/g, '');
  }
  return url;
}

function formatUrlSegment(string?: string, key?: string) {
  if (!string || !key) return '';

  // FIXME: Pure Flix specific because video url is used as an id
  if (key === 'id') return '/' + getVideoIdFromUrl(string);

  return '/' + convertStringToUrlSegment(string);
}

export function generateURL(screenURL: string, entry: any) {
  const matches = screenURL?.match(
    /^\/([\w-_]+)\/(\{\{([\w-_]+)\}\}(?:\/\{\{([\w-_]+)\}\})?(?:\/\{\{([\w-_]+)\}\})?)?$/
  );

  if (matches) {
    const segment1 = matches[1];
    const segment2 = formatUrlSegment(get(entry, matches?.[3]), matches?.[3]);
    const segment3 = formatUrlSegment(get(entry, matches?.[4]), matches?.[4]);
    const segment4 = formatUrlSegment(get(entry, matches?.[5]), matches?.[5]);

    return `/${segment1}${segment2}${segment3}${segment4}`;
  }

  // Hardcoded. What if I put different keys? - Replace with regexp
  return screenURL?.replace('{{id}}/{{title}}', '');
}

export function getLinkTo({
  entryPayload,
  contentTypes,
  linksToScreenData,
  feedPath,
  video,
  debug,
  debugLayoutId,
}: {
  entryPayload: any;
  contentTypes: any;
  linksToScreenData: any;
  feedPath?: string;
  video?: boolean;
  debug?: string | null | undefined;
  debugLayoutId?: string | null | undefined;
}): string | undefined {
  try {
    const {
      type: { value },
    } = entryPayload;

    const contentType: string | undefined = entryPayload?.content?.type;

    if (entryPayload?.type?.value === 'link') {
      const linkUrl = getUserAccountLink(entryPayload);
      if (typeof linkUrl !== 'undefined') return `external-link::${linkUrl}`;
    }

    const screenId: string = getScreenIdByType(value, contentTypes);
    const screen = getScreen(screenId, linksToScreenData);

    const selfLink =
      entryPayload?.link?.rel && 'self' && entryPayload?.link?.href;

    const isVideo: boolean | undefined = contentType
      ? contentType?.startsWith('video')
      : undefined;

    if (
      video ||
      (screen?.type && playerPlugins?.includes(screen?.type)) ||
      (isVideo && !screen) ||
      (selfLink && !screen)
    ) {
      const videoParams: any = {
        'self-link': selfLink,
        'entry-id': !selfLink && entryPayload?.id,
        'feed-path': !selfLink && feedPath,
        'screen-id': screenId,
      };

      return `/${playerRoute}?${new URLSearchParams(
        Object.keys(videoParams).reduce((acc: any, key) => {
          if (videoParams[key]) {
            acc[key] = videoParams[key];
          }
          if (debug) {
            acc.debug = debug;
          }
          if (debugLayoutId) {
            acc.debugLayoutId = debugLayoutId;
          }
          return acc;
        }, {})
      )}`;
    }

    const screenURL: string | undefined =
      getScreenRelativePath(screen) || screenId;

    const url: string = generateURL(screenURL, entryPayload);

    if (url)
      return addParametersToURL(url, { debug, 'layout-id': debugLayoutId });

    return url;
  } catch (error: any) {
    // logger.info(`Error in getLinkTo: ${error.message}`);
    return undefined;
  }
}

function getUserAccountLink(entryPayload: any): string | undefined {
  try {
    const contentSrc: string | undefined = entryPayload?.content?.src;

    if (!isValidUrl(contentSrc)) return undefined;

    return contentSrc;
  } catch (error: any) {
    return undefined;
  }
}
