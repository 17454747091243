import { useLoaderData } from '@remix-run/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useBottomNavbarHeight } from '~/hooks/use-bottom-navbar-height.ts';

import logger from '~/services/logger';

import type { loader as rootLoader } from '~/routes/_index.ts';
import { sortByPositionKey } from '~/services/layout/layout';
import NavItemCookieSettings from '~/services/plugins/nav-item-cookie-settings/components/index.tsx';
import manifest from '~/services/plugins/nav-item-link/config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';

export default function Footer(): JSX.Element {
  const { footerData } = useLoaderData<typeof rootLoader>();

  try {
    const bottomNavbarHeight: number = useBottomNavbarHeight();

    const bottomNavbarHeightStyle = {
      height: `${bottomNavbarHeight}px`,
      backgroundColor: 'transparent',
    };

    if (!footerData) return <></>;

    // @ts-ignore
    const { navItems: footerNavItems, id, type } = footerData;

    const navItems: NormalizeFooterData[] | undefined =
      type === 'nested_footer'
        ? normalizeNestedFooterData(footerNavItems)
        : normalizeFooterData(footerNavItems);

    if (!navItems || navItems?.length === 0) return <></>;

    if (!id)
      throw new Error(
        'Footer component requires an id from the navigation data'
      );

    return (
      <>
        <footer
          className={twMerge(
            `footer-${id} mb-footer-b ml-footer-l mr-footer-r mt-footer-t flex flex-col gap-8 bg-footer pb-footer-b pl-footer-l pr-footer-r pt-footer-t tracking-footer-letter-spacing`
          )}
        >
          {React.Children.toArray(
            navItems.map((navItem: any) => {
              if (navItem?.section_layout === 'horizontal') {
                if (!navItem?.items || navItem?.items?.length === 0)
                  return <></>;

                const horizontalSectionId = navItem?.id;
                if (!horizontalSectionId) return <></>;

                return (
                  <section
                    className={twMerge(
                      `footer-section-${horizontalSectionId} mb-footer-b ml-footer-l mr-footer-r mt-footer-t flex flex-wrap items-center gap-6 bg-footer pb-footer-b pl-footer-l pr-footer-r pt-footer-t font-footer-font-family text-footer-font-size font-footer-font-weight tracking-footer-letter-spacing text-footer-font-color`,
                      `justify-${getFooterSectionAlign(
                        navItem?.styles?.section_align
                      )}`
                    )}
                  >
                    {React.Children.toArray(
                      navItem.items.map((item: any) => {
                        return (
                          <>
                            <FooterItem
                              item={item}
                              iconSize={navItem?.styles?.icon_size}
                            />
                          </>
                        );
                      })
                    )}
                  </section>
                );
              }

              if (navItem?.section_layout === 'grid') {
                if (!navItem?.items || navItem?.items?.length === 0)
                  return <></>;

                const gridSectionId = navItem?.id;
                if (!gridSectionId) return <></>;

                return (
                  <>
                    <section className="flex justify-center">
                      <div
                        className={twMerge(
                          `footer-section-${gridSectionId} mb-footer-b ml-footer-l mr-footer-r mt-footer-t flex flex-col gap-6 bg-footer pb-footer-b pl-footer-l pr-footer-r pt-footer-t font-footer-font-family text-footer-font-size font-footer-font-weight tracking-footer-letter-spacing text-footer-font-color lg:flex-row lg:gap-20`,
                          `text-${getFooterSectionAlign(
                            navItem?.styles?.section_align
                          )}`
                        )}
                      >
                        {React.Children.toArray(
                          navItem.items.map((item: any) => {
                            return (
                              <div className="flex flex-col gap-1">
                                {React.Children.toArray(
                                  item.map((gridItem: any) => {
                                    return (
                                      <FooterItem
                                        item={gridItem}
                                        iconSize={navItem?.styles?.icon_size}
                                      />
                                    );
                                  })
                                )}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </section>
                  </>
                );
              }

              return <></>;
            })
          )}
        </footer>
        {bottomNavbarHeight > 0 && <div style={bottomNavbarHeightStyle}></div>}
      </>
    );
  } catch (error: any) {
    logger.info(`Footer Component: ${error.message}`);
    return <></>;
  }
}

function FooterItem({
  item,
  iconSize = 20,
}: {
  item: SectionItem;
  iconSize: number;
}): JSX.Element {
  try {
    if (item?.type === 'nav_item_cookie_settings')
      return <NavItemCookieSettings item={item} />;

    const { general, assets } = getPluginConfig<typeof manifest>(
      manifest,
      item
    );

    return (
      <div
        className={twMerge(
          item?.type !== 'icon_link' && 'text-footer-font-size'
        )}
      >
        {item?.gridTitle && (
          <span style={{ fontWeight: 'bolder' }}>{item.gridTitle}</span>
        )}
        <a
          className={twMerge(
            'opacity-0',
            'inline-flex gap-1 whitespace-nowrap',
            assets?.icon_position === 'right' && 'flex-row-reverse',
            'opacity-footer-link-opacity'
          )}
          href={general?.url}
          target={general?.target || '_self'}
        >
          {item?.assets?.icon && (
            <img
              className={twMerge(
                item?.type === 'icon_link' && `aspect-square`,
                item?.type === 'nav_item_link' && 'mt-[0.3em] h-[1em]'
              )}
              style={{ height: iconSize }}
              src={assets?.icon}
              alt={assets?.alt_text || ''}
            />
          )}
          {general?.text && <p>{general?.text}</p>}
        </a>
      </div>
    );
  } catch (error: any) {
    logger.info(`FooterItem Component: ${error.message}`);
    return <></>;
  }
}

function getFooterSectionAlign(alignValue: string | undefined): string {
  if (!alignValue) return 'center';
  if (alignValue === 'left') return 'start';
  if (alignValue === 'center') return 'center';
  if (alignValue === 'right') return 'end';
  return 'center';
}

export function normalizeFooterData(
  footerNavItems: FooterData[]
): NormalizeFooterData[] | undefined {
  try {
    const sections: any[] = [];
    let currentSection: any | null = null;

    let currentSectionGroup: any[] | null = null;

    for (let i = 0; i < footerNavItems.length; i++) {
      const item: FooterData = footerNavItems[i];

      if (item.type === 'nav_section') {
        currentSection = {
          id: item.id,
          section_layout: item.general.section_layout,
          section_order_number: sections.length + 1,
          styles: item.styles,
          items: [],
        };

        sections.push(currentSection);

        currentSectionGroup = null;
      } else if (item.type === 'section_group') {
        const title = item?.general?.title;

        currentSectionGroup = [];

        if (title && title !== '')
          currentSectionGroup.push({ gridTitle: title });

        currentSection.items.push(currentSectionGroup);
      } else {
        if (currentSectionGroup) {
          currentSectionGroup.push(item);
        } else if (currentSection) {
          currentSection.items.push(item);
        }
      }
    }

    return filterGridItemsWithoutSectionGroup(sections);

    function filterGridItemsWithoutSectionGroup(
      footerSections: NormalizeFooterData[]
    ): any {
      const gridIndexs: any = [];
      let gridItems: any = [];
      const footerSectionsCopy = Array.from(footerSections);

      footerSections.forEach((item: any, i: number) => {
        if (item?.section_layout !== 'grid') return;
        gridIndexs?.push(i);
        gridItems[i] = removeInvalidGridItems(item?.items);
      });

      gridIndexs?.forEach((index: number) => {
        footerSectionsCopy[index].items = gridItems[index];
      });

      return footerSectionsCopy;

      function removeInvalidGridItems(gridItem: any[][]): any[][] {
        return gridItem.filter((subArr) => Array.isArray(subArr));
      }
    }
  } catch (error: any) {
    logger.info(`normalizeFooterData: ${error.message}`);
  }
}

export function normalizeNestedFooterData(
  footerNavItems: FooterData[]
): NormalizeFooterData[] | undefined {
  try {
    const navItems: any[] = [];

    footerNavItems.forEach((item: any) => {
      const isHorizontalSection: boolean =
        item?.identifier === 'nested_footer_horizontal_section';
      const isGridSection: boolean =
        item?.identifier === 'nested_footer_grid_section';

      if (!isHorizontalSection && !isGridSection) return;

      const navItem: any = {
        id: item?.id,
        section_layout: isHorizontalSection ? 'horizontal' : 'grid',
        styles: item?.styles,
        items: [],
      };

      item?.nav_items?.forEach((nestedItem: any) => {
        if (nestedItem?.identifier === 'grid_section_column') {
          const gridItem: any[] = [
            {
              gridTitle: nestedItem?.general?.title,
              columnPosition: nestedItem?.position,
            },
          ];

          nestedItem?.nav_items?.forEach((gridColumnItem: any) => {
            gridItem.push({
              type: gridColumnItem?.identifier,
              general: gridColumnItem?.general,
              assets: gridColumnItem?.assets,
              position: gridColumnItem?.position,
            });
          });

          navItem?.items?.push(gridItem);
          return;
        }

        navItem?.items?.push({
          type: nestedItem?.identifier,
          general: nestedItem?.general,
          assets: nestedItem?.assets,
          position: nestedItem?.position,
        });
      });

      navItems.push(navItem);
    });

    return sortNestedFooterItems(navItems);
  } catch (error: any) {
    logger.info(`normalizeNestedFooterData: ${error.message}`);
  }
}

export function sortNestedFooterItems(
  normalizeFooterData: NormalizeFooterData[]
): NormalizeFooterData[] | undefined {
  try {
    if (!Array.isArray(normalizeFooterData) || normalizeFooterData.length === 0)
      return;

    normalizeFooterData.forEach((footerSection) => {
      if (
        footerSection.section_layout === 'grid' &&
        Array.isArray(footerSection.items)
      ) {
        footerSection.items.sort(
          (a: any, b: any) => a[0].columnPosition - b[0].columnPosition
        );

        footerSection.items.forEach((subArray: any[]) => {
          if (Array.isArray(subArray)) {
            sortByPositionKey(subArray);
          }
        });

        return;
      }

      if (
        footerSection.section_layout === 'horizontal' &&
        Array.isArray(footerSection.items)
      ) {
        sortByPositionKey(footerSection.items);
      }
    });

    return normalizeFooterData;
  } catch (error: any) {
    logger.info(`sortNestedFooterItems: ${error.message}`);
  }
}

/* ZAPP FOOTER NAVIGATION TYPES */
export type FooterData = Section | IconLink | SectionGroup | Link | Text;

type FooterSectionStyles = {
  background_color: string;
  padding_top: number;
  padding_right: number;
  padding_bottom: number;
  padding_left: number;
  margin_top: number;
  margin_right: number;
  margin_bottom: number;
  margin_left: number;
  font_family: string;
  font_size: number;
  font_color: string;
  font_weight: string;
  section_align: string;
  letter_spacing: number;
  icon_size: number;
};

interface Section {
  id: string;
  type: 'nav_section';
  general: {
    section_layout: 'horizontal' | 'grid';
  };
  styles: FooterSectionStyles;
}

interface IconLink {
  type: 'icon_link';
  assets: {
    icon: string;
    alt_text: string;
  };
  general: {
    url: string;
    target: string;
  };
}

interface SectionGroup {
  type: 'section_group';
  general: {
    title: string;
  };
}

interface Link {
  type: 'nav_item_link';
  assets?: {
    icon: string;
    icon_position: string;
    alt_text: string;
  };
  general: {
    url: string;
    target: string;
    text: string;
  };
}

interface Text {
  type: 'text';
  general: {
    text: string;
  };
}

/* NORMALIZE FOOTER DATA TYPES */
export interface NormalizeFooterData {
  section_layout: string;
  section_order_number: number;
  items: (SectionItem[] | SectionGroupItem[])[];
}

interface SectionItem {
  id: string;
  type: string;
  gridTitle?: string;
  assets?: {
    icon?: string;
    icon_position?: string;
    alt_text?: string;
  };
  general?: {
    url?: string;
    target?: string;
    text?: string;
  };
  styles: FooterSectionStyles;
}

interface SectionGroupItem {
  title: string;
}
