import { useEffect } from 'react';

export default function useOutsideClick({
  elementRef,
  toggleSetter,
  enabled,
}: {
  elementRef: React.RefObject<HTMLDivElement>;
  toggleSetter: (isOpen: boolean) => void;
  enabled: boolean;
}) {
  const handleClickOutside = (event: { target: any }) => {
    if (!elementRef.current) return;

    const closeOnOutsideClick =
      !elementRef.current.contains(event.target) && enabled;

    if (closeOnOutsideClick) toggleSetter(false);
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && enabled) toggleSetter(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [elementRef, enabled, toggleSetter]);
}
