import { useEffect, useState } from 'react';
import { useIsMobile } from './use-responsive-breakpoint.ts';

/**
 * Returns the height of the bottom navbar.
 * If footer padding bottom is greater than navbar height, return 0.
 * Purpose: To prevent footer content from being hidden behind the bottom navbar.
 * @returns {number} height | 0
 */
export function useBottomNavbarHeight(): number {
  const [bottomNavbarHeight, setBottomNavbarHeight] = useState<number>(0);

  const isMobile: boolean = useIsMobile();

  useEffect(() => {
    if (!isMobile) {
      setBottomNavbarHeight(0);
      return;
    }

    const footerPaddingBottom: number = getFooterPaddingMarginBottom();

    const bottomNavbarHeight: number = getBottomNavbarHeight();

    if (footerPaddingBottom >= bottomNavbarHeight) {
      setBottomNavbarHeight(0);
      return;
    }

    setBottomNavbarHeight(bottomNavbarHeight);
  }, [isMobile]);

  return bottomNavbarHeight;
}

function getBottomNavbarHeight() {
  try {
    const navElement = document.querySelector('[data-bottom-navbar]');

    if (navElement) return navElement.clientHeight;

    return 0;
  } catch (error) {
    return 0;
  }
}

function getFooterPaddingMarginBottom() {
  try {
    const div = document.createElement('div');

    div.className = 'pb-footer-b mb-footer-b';

    document.body.appendChild(div);

    const padddingBottom = getComputedStyle(div).paddingBottom;

    const marginBottom = getComputedStyle(div).marginBottom;

    const sum: number = parseFloat(padddingBottom) + parseFloat(marginBottom);

    document.body.removeChild(div);

    return sum;
  } catch (error) {
    return 0;
  }
}
