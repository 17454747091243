import { useCallback } from 'react';
import type { PropsWithChildren } from 'react';
import { Link, useLoaderData } from '@remix-run/react';

import { track } from '~/services/analytics/index.client.ts';
import type { AnalyticsData } from '~/utils/analytics';
import { isValidUrl } from '~/utils/validations';
import type { loader as rootLoader } from '~/routes/_index.ts';
import { RemixLinkProps } from '@remix-run/react/dist/components';

export const MaybeLink = (
  props: PropsWithChildren<{
    to?: string;
    analyticsdata?: AnalyticsData;
    enabled?: boolean;
    linkariainfo?: string;
  }>
) => {
  const { deviceType } = useLoaderData<typeof rootLoader>();
  const propsTo = props.to;

  const isVideo = typeof propsTo === 'string' && propsTo?.startsWith('/player');

  const onClickLink = useCallback(() => {
    const { eventName, trackData } = props?.analyticsdata || {};
    if (eventName && trackData) {
      track(eventName, trackData);
    }
  }, [props?.analyticsdata]);

  if (!propsTo || props.enabled === false) return <>{props.children}</>;

  let hasExternalUrl = false;

  if (isValidUrl(propsTo)) {
    const url = new URL(propsTo);
    hasExternalUrl = ['true'].includes(
      url.searchParams.get('external_url') || ''
    );
  }

  const isExternalLink: boolean = propsTo?.startsWith('external-link::');

  if (!isExternalLink)
    return (
      <Link
        target={hasExternalUrl ? '_blank' : '_self'}
        onClick={onClickLink}
        aria-label={props.linkariainfo}
        to={propsTo}
        reloadDocument={isVideo}
        prefetch={getPrefetchBehavior(isVideo, deviceType)}
      >
        {props.children}
      </Link>
    );

  const externalLink = propsTo.slice(15);

  return (
    <>
      <a
        href={externalLink}
        aria-label={props.linkariainfo}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    </>
  );
};

function getPrefetchBehavior(
  isVideo: boolean,
  deviceType: string
): RemixLinkProps['prefetch'] {
  if (isVideo) return 'none';

  if (deviceType === 'desktop') return 'intent';

  return 'viewport';
}
