const manifest = {
  plugin_id: 3415,
  identifier: 'nav_item_link',
  manifest_version: '1.0.0',
  dependency_name: '',
  name: 'Nav item link',
  description: 'nav_item_link',
  type: 'nav_item',
  general: {
    fields: [
      {
        key: 'url',
        label: 'URL',
        type: 'text_input',
      },
      {
        key: 'target',
        label: 'Target',
        options: [
          {
            text: 'Self',
            value: '_self',
          },
          {
            text: 'Blank',
            value: '_blank',
          },
        ],
        type: 'select',
      },
      {
        key: 'text',
        label: 'Text',
        type: 'text_input',
      },
    ],
  },
  assets: {
    fields: [
      {
        key: 'icon',
        label: 'Icon',
        type: 'uploader',
      },
      {
        key: 'alt_text',
        label: 'Alt Text',
        type: 'text_input',
      },
      {
        key: 'icon_position',
        label: 'Icon Position',
        options: [
          {
            text: 'Left',
            value: 'left',
          },
          {
            text: 'Right',
            value: 'right',
          },
        ],
        default: 'left',
        type: 'select',
      },
    ],
  },
} as const;

export default manifest;
