const manifest = {
  plugin_id: 3433,
  identifier: 'nav_item_cookie_settings',
  manifest_version: '1.0.0',
  dependency_name: '',
  name: 'Cookie settings button',
  description: 'Cookie settings button',
  type: 'nav_item',
  general: {
    fields: [
      {
        key: 'text',
        label: 'Button Text',
        initial_value: 'Cookie settings',
        label_tooltip:
          'The text to display on the button, if empty the default text of "Cookie settings" will be used.',
        type: 'text_input',
      },
    ],
  },
} as const;

export default manifest;
