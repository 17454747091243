import { twMerge } from 'tailwind-merge';
import { UserAccountDropdown } from './user-account-dropdown.tsx';
import { SearchIconAsset } from '../screens/search.tsx';
import type { NavBarJSON } from '~/services/layout/navigations.ts';

export function NavBarToggleMenu({
  navBarId,
  isMenuOpen,
  toggleMenu,
  navbarStyles,
}: {
  navBarId: string | undefined;
  isMenuOpen: boolean;
  toggleMenu: () => void;
  navbarStyles: NavBarJSON['styles'];
}) {
  const mobileToggleTopSeparatorSwitch: boolean =
    navbarStyles?.mobile_toggle_top_separator_switch || false;
  return (
    <>
      <div className="ml-auto flex items-center lg:hidden">
        <MenuBarToggleButton
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          navbarStyles={navbarStyles}
        />
      </div>

      <div
        className={twMerge(
          `navigation-${navBarId} bg-mobile-toggle-background-color font-navbar-font-family font-navbar-font-weight tracking-navbar-letter-spacing text-navbar-font-color`,
          `absolute left-0 top-full w-full transition-opacity duration-500 ease-in-out`,
          mobileToggleTopSeparatorSwitch &&
            'border-t-2 border-mobile-toggle-top-separator-color',
          isMenuOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        )}
        aria-hidden={!isMenuOpen}
      >
        <div className="flex items-center p-6">
          <UserAccountDropdown />
          <SearchIconAsset />
        </div>
      </div>
    </>
  );
}

function MenuBarToggleButton({
  toggleMenu,
  isMenuOpen,
  navbarStyles,
}: {
  toggleMenu: () => void;
  isMenuOpen: boolean;
  navbarStyles: NavBarJSON['styles'];
}) {
  const mobileToggleButtonSize: number =
    navbarStyles?.mobile_toggle_button_size || 24;

  return (
    <>
      {isMenuOpen ? (
        <CloseMenuIcon size={mobileToggleButtonSize} onClick={toggleMenu} />
      ) : (
        <OpenMenuIcon size={mobileToggleButtonSize} onClick={toggleMenu} />
      )}
    </>
  );
}

// Menu bar X icon
function CloseMenuIcon({
  size,
  onClick,
}: {
  size: number;
  onClick: () => void;
}): JSX.Element {
  const viewBox: string = `0 0 ${size} ${size}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      width={size}
      height={size}
      className="h-mobile-toggle-button-size w-mobile-toggle-button-size stroke-mobile-toggle-button-color stroke-2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      aria-checked="true"
      role="switch"
    >
      <line x1="18" y1="6" x2="6" y2="18" />
      <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
  );
}

// Menu bar ☰ icon
function OpenMenuIcon({
  size,
  onClick,
}: {
  size: number;
  onClick: () => void;
}): JSX.Element {
  const viewBox: string = `0 0 ${size} ${size}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      width={size}
      height={size}
      className="h-mobile-toggle-button-size w-mobile-toggle-button-size stroke-mobile-toggle-button-color stroke-2"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      aria-checked="false"
      role="switch"
    >
      <line x1="3" y1="12" x2="21" y2="12" />
      <line x1="3" y1="6" x2="21" y2="6" />
      <line x1="3" y1="18" x2="21" y2="18" />
    </svg>
  );
}
